import * as React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { themeMap, type ThemeMapTypeT, Primary } from '../Themes';
import { Box, type BoxT } from '../Box';
import { Text } from '../Text';
import { Link, type LinkT } from '../Link';
import { Prompt, type PromptT } from '../Prompt';
import type { ActionPatternT } from '../Group';
export interface BannerT extends BoxT {
  children?: React.ReactNode;
  link?: LinkT;
  theme?: ThemeMapTypeT;
  title?: string | undefined;
  actionPattern?: ActionPatternT;
  actions?: PromptT['actions'];
  onDismiss?: PromptT['onDismiss'];
}

export const Banner = ({
  title,
  link,
  actions,
  onDismiss,
  actionPattern = 'c',
  theme,
  children,
  className,
  ...rest
}: BannerT) => {
  const content = (
    <Box
      className={cn(
        'items-center rounded-2xl z-notification w-full bg',
        className,
      )}
      {...rest}
    >
      <Prompt
        dismissLabel="Close Banner"
        onDismiss={onDismiss}
        actions={actions}
        actionPattern={actionPattern}
        className="w-full bg-transparent"
      >
        <Box className={`${onDismiss ? 'mx-6' : 'mx-0'} items-center`}>
          {title && (
            <Text
              className={`${actionPattern === 'c' ? 'text-center' : 'text-left'}
                font-normal text-inherit md:text-100 text-87.5
              `}
            >
              {title}
              {link && (
                <Link
                  className="ml-3 text-inherit text-size-inherit"
                  {...link}
                />
              )}
            </Text>
          )}
          {children}
        </Box>
      </Prompt>
    </Box>
  );
  if (theme) {
    const T = themeMap[theme];
    return <T>{content}</T>;
  }
  return <Primary>{content}</Primary>;
};
