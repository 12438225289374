import { useEffect, useState } from 'react';

/** Hook that returns whether the component has mounted: always true after the very first render cycle.
 * You should use this sparingly, but it can be useful to check whether you can start accessing the DOM
 * in other hooks.
 */
export const useHasMounted = (): boolean => {
  const [state, setState] = useState(false);
  useEffect(() => {
    setState(true);
  }, []);
  return state;
};
